






























































































import { Component } from 'vue-property-decorator'
import { SelenaForm } from './SelenaFormWrapper'

@Component({
  name: 'InviteToSite'
})
export class InviteToSite extends SelenaForm {
  public formData = {
    name: '',
    email: '',
    phoneNumber: '',
    trainingDate: '',
    address: '',
    zipCode: '',
    city: ''
  }

  public get defaultDate () {
    return new Date()
  }

  public disabledDates (date: Date): boolean {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    return date < today
  }
}
export default InviteToSite
