import { AbstractModuleUi } from '../../abstract/ui'
import { SelenaFormModule } from '../SelenaForm.contracts'
import { Component, Prop } from 'vue-property-decorator'
import Consents from '../../../front/shared/molecules/Consents/Consents.vue'
import { FormOptions } from '../../../front/shared/services/selenaForms'

@Component({
  name: 'SelenaFormBase',
  components: { Consents }
})
export class SelenaForm extends AbstractModuleUi<SelenaFormModule> {
  @Prop({ type: Boolean, required: true })
  public readonly isLoading!: boolean

  @Prop({ type: Object, required: true })
  public readonly options!: FormOptions

  public formData = {}

  public getErrors () { return [] }

  public onSubscribe () {
    this.$emit('submitForm', this.formData)
  }

  public get consentsOptions () {
    return this.options.consentsOptions || []
  }
}
